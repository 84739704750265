<template>
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
            <path d="M3982 3829 c-46 -14 -118 -88 -131 -135 -7 -25 -11 -201 -11 -493 0
            -330 -3 -465 -12 -495 -16 -53 -81 -118 -134 -134 -32 -9 -286 -12 -1075 -12
            l-1034 0 147 148 c157 157 187 202 188 280 0 112 -95 211 -204 212 -97 0 -98
            0 -469 -369 -240 -239 -354 -359 -369 -390 -27 -57 -28 -133 0 -186 12 -23
            167 -186 374 -392 315 -314 358 -353 398 -363 138 -37 270 67 270 212 0 71
            -25 108 -182 265 l-152 152 1074 4 c1197 3 1107 -2 1260 74 110 54 224 170
            278 284 67 142 67 138 67 694 l0 500 -28 47 c-49 84 -160 126 -255 97z"/>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'icon-enter',
}
</script>

<style lang="scss" scoped>

</style>